import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './utils/store';

const app = createApp(App);

app.use(router)
app.use(store)
app.mount('#app')
