<template>
	<div class="nb-home-page-container">
		<ModalContainer modalTitle="登录/注册" @close="onRegisterModalClose" v-if="isRegisterModalOpen === true">
			<RegisterModalContent @registerSuccess="onRegisterAccountSuccess"
			                      @loginSuccess="onLoginSuccess"></RegisterModalContent>
		</ModalContainer>
		<ModalContainer modalTitle="账户信息" @close="onAccountInfoClose" v-if="isAccountInfoModalOpen === true">
			<UserInfoContent @userLogout="onUserLogout"/>
		</ModalContainer>
		<ModalContainer modalTitle="选择支付方式" @close="onPaymentMethodModalClose"
		                v-if="isPaymentMethodModalOpen === true">
			<PaymentMethodsContent :selectedPlanType="this.selectedPlanType"/>
		</ModalContainer>
		<ModalContainer modalTitle="" :showClose="false" v-if="showConfirmDialog === true">
			<div class="nb-confirm-btn-container">
				<div class="nb-confirm-dialog-text">{{ this.confirmModalTitle }}</div>
				<div class="nb-confirm-btn-row">
					<div class="nb-confirm-btn" @click="onConfirmBtnClick">确认</div>
					<div class="nb-cancel-btn" @click="onCancelBtnClick">取消</div>
				</div>

			</div>
		</ModalContainer>

		<ModalContainer modalTitle="选择下载类型" @close="this.onWindowsDownloadModalClose" v-if="openWindowsDownloadModal === true">
			<div class="select-download-type-container">
				<div class="select-download-type-column">
					<div class="select-download-framework">.NET Framework 6.0</div>
					<div class="select-download-system">适配系统：<br>Windows 10, Windows 11</div>
					<div class="select-download-type-download-btn nb-btn" @click="this.downloadWindowsApp('net6.0')">下载</div>
				</div>
				<div class="select-download-type-column">
					<div class="select-download-framework">.NET Framework 3.1</div>
					<div class="select-download-system">适配系统：<br>Windows 7, Windows 8</div>
					<div class="select-download-type-download-btn nb-btn" @click="this.downloadWindowsApp('net3.1')">下载</div>
				</div>
			</div>
		</ModalContainer>
		<div class="nb-mobile-menu-container" v-if="isMobileMenuOpen === true">
			<img src="../images/close.svg" alt="close" class="nb-mobile-close-menu-image"
			     @click="onMobileMenuMaskClicked">
			<div class="nb-mobile-menu-item" @click="onProductFeatureClicked">
				<img src="../images/menu/product.svg" alt="product" class="nb-mobile-menu-image">
				产品特点
			</div>
			<div class="nb-mobile-menu-item" @click="onDownloadClicked">
				<img src="../images/menu/app_download.svg" alt="app download" class="nb-mobile-menu-image">
				应用下载
			</div>
			<div class="nb-mobile-menu-item" @click="onPurchaseMembershipClicked">
				<img src="../images/menu/membership.svg" alt="membership" class="nb-mobile-menu-image">
				购买会员
			</div>
			<div class="nb-mobile-menu-item" @click="onUserCommentClicked">
				<img src="../images/menu/comments.svg" alt="membership" class="nb-mobile-menu-image">
				用户评价
			</div>
			<div class="nb-mobile-menu-item" @click="onRegisterAccountClicked">
				<img src="../images/menu/account.svg" alt="account" class="nb-mobile-menu-image">
				{{ this.isUserLogin === true ? '账户信息' : '注册/登录' }}
			</div>
		</div>
		<div class="nb-mobile-menu-mask" v-if="isMobileMenuOpen === true" @click="onMobileMenuMaskClicked"/>
		<div class="nb-first-section">
			<div class="nb-header-container">
				<img src="../images/sl_logo.svg" alt="app logo" class="nb-web-logo">
				<div class="nb-web-title">星云加速器 永不失连</div>
				<div class="nb-menu-container">
					<div class="nb-menu-item" @click="onProductFeatureClicked">
						<img src="../images/menu/product.svg" alt="product" class="nb-mobile-menu-image">
						产品特点
					</div>
					<div class="nb-menu-item" @click="onDownloadClicked">
						<img src="../images/menu/app_download.svg" alt="app download" class="nb-mobile-menu-image">
						应用下载
					</div>
					<div class="nb-menu-item" @click="onPurchaseMembershipClicked">
						<img src="../images/menu/membership.svg" alt="membership" class="nb-mobile-menu-image">
						购买会员
					</div>
					<div class="nb-menu-item" @click="onUserCommentClicked">
						<img src="../images/menu/comments.svg" alt="membership" class="nb-mobile-menu-image">
						用户评价
					</div>
					<div class="nb-menu-item" @click="onRegisterAccountClicked">
						<img src="../images/menu/account.svg" alt="account" class="nb-mobile-menu-image">
						{{ this.isUserLogin === true ? '账户信息' : '注册/登录' }}
					</div>
					<img src="../images/menu.svg" alt="menu" class="nb-menu-img" @click="onMobileMenuClicked">
				</div>
			</div>
			<div class="nb-first-download-container">
				<div class="nb-download-text-section" id="nb_banner_container">
					<!--					<div class="nb-banner-image-container">-->
					<!--						<img src="../images/banner.png" alt="banner" class="nb-banner-image">-->
					<!--					</div>-->

					<div class="nb-sub-header">新一代网络加速器，保护数据安全，解锁区域流媒体，让您畅游网络无后顾之忧！
					</div>
					<div class="nb-first-section-download-btn" @click="onDownloadClicked">
						<div>立即下载</div>
						<img src="../images/download.svg" alt="download" class="nb-download-file-image">
					</div>
				</div>
				<div class="nb-product-image-section">
					<img src="../images/products.png" alt="products" class="nb-products-image">
				</div>
			</div>
		</div>
		<div class="nb-hr"></div>
		<div class="nb-feature-section" id="nb_product_feature_section">
			<div class="nb-feature-title">产品特点</div>
			<div class="nb-feature-grid-container">
				<div class="nb-feature-card-container">
					<img src="../images/node.svg" alt="node" class="nb-feature-img">
					<div class="nb-feature-card-text">全球多个国家的中转节点</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/customer_service.svg" alt="customer service" class="nb-feature-img">
					<div class="nb-feature-card-text">快速技术支持，解决疑难杂症</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/encrypt.svg" alt="encrypt" class="nb-feature-img">
					<div class="nb-feature-card-text">双向加密通信，捍卫您的隐私</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/ai.svg" alt="node" class="nb-feature-img">
					<div class="nb-feature-card-text">独特算法，突破网络管控</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/sat.svg" alt="node" class="nb-feature-img">
					<div class="nb-feature-card-text">优化线路，低延时大带宽</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/os.svg" alt="node" class="nb-feature-img">
					<div class="nb-feature-card-text">支持四大主流操作系统</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/team.svg" alt="node" class="nb-feature-img">
					<div class="nb-feature-card-text">前大厂技术团队研发</div>
				</div>
				<div class="nb-feature-card-container">
					<img src="../images/brand_width.svg" alt="node" class="nb-feature-img">
					<div class="nb-feature-card-text">打游戏看电影丝般顺滑</div>
				</div>
			</div>
		</div>

		<div class="nb-hr"/>
		<div class="nb-download-section" id='nb_download_section'>
			<div class="nb-download-section-title">立即下载，开始免费使用</div>
			<div class="nb-os-download-container">
				<div class="nb-os-download-card">
					<img src="../images/os_win.svg" alt="windows" class="nb-os-download-image">
					<div class="nb-os-download-text">Windows</div>
					<div class="nb-os-download-btn" @click="onWindowsDownloadClicked">
						下载
					</div>
				</div>
				<div class="nb-os-download-card">
					<img src="../images/os_and.svg" alt="android" class="nb-os-download-image">
					<div class="nb-os-download-text">Android</div>
					<div class="nb-os-download-btn" @click="onAndroidDownloadClicked">
						下载
					</div>
				</div>
				<div class="nb-os-download-card">
					<img src="../images/os_ios.svg" alt="ios" class="nb-os-download-image">
					<div class="nb-os-download-text">iPhone</div>
					<div class="nb-os-download-btn" @click="onIosDownloadClicked">
						下载
					</div>
				</div>
				<div class="nb-os-download-card">
					<img src="../images/os_macos.svg" alt="macos" class="nb-os-download-image">
					<div class="nb-os-download-text">iMac</div>
					<div class="nb-os-download-btn" @click="onMacDownloadClicked">
						下载
					</div>
				</div>
			</div>

		</div>
		<div class="nb-hr"/>
		<div class="nb-price-section" id="nb_purchase_membership_section">
			<div class="nb-price-section-title">购买会员</div>
			<div class="nb-price-card-container">
				<div v-for="item in this.planInfoList" :key="item.code" class="nb-price-card">
					<img v-if="item.promoCode === 'FIRST_BUY'" class="nb-price-card-first-charge-tag"
					     src="../images/first_charge_tag.svg" alt="first charge">
					<div class="nb-price-card-title">{{ item.name }}</div>
					<!--					<div v-if="item.promoDes" class="nb-price-card-promo-title">{{item.promoDes}}</div>-->
					<div v-if="!item.promoDuration" class="nb-price-card-text">{{ item.duration }}天有效期</div>
					<div v-if="item.promoDuration" class="nb-price-card-text-with-strip">{{
							item.duration
						}}天有效期
					</div>
					<div v-if="item.promoDuration" class="nb-price-card-promo-text">{{
							item.promoDuration
						}}天有效期
					</div>
					<div class="nb-price-card-price">售价：￥{{ item.price }}</div>
					<div class="nb-price-card-text">客户端数量：{{ item.deviceCount }}</div>
					<div class="nb-price-card-text">流量：无限制</div>
					<div class="nb-price-card-btn" @click="onPaymentMethodModalOpen(item.code)">

						{{ this.getPriceCardActionText(item) }}
					</div>
				</div>
			</div>
		</div>
		<div class="nb-hr"/>

		<div class="nb-feedback-section" id="nb_user_comment_section">
			<div class="nb-feature-title">用户评价</div>
			<div class="nb-feedback-comment">
				{{ this.currentComment }}
			</div>
			<swiper
				ref="userSwiper"
				class="nb-swiper-container"
				:slides-per-view=sliderPerView
				:space-between="50"
				:modules="modules"
				@slideChange="onSlideChange"
				:autoplay=true
				:navigation=true
				:loop=true
				:simulateTouch=false
				:pagination=true>
				<swiper-slide v-for="(user, index) in userList" :key="index" class="nb-swiper-slide-container">
					<div class="nb-swiper-content-wrapper">
						<img :src=user.img alt="user image" class="nb-swiper-img">
						<div class="nb-swiper-name">{{ user.name }}</div>
						<div class="nb-swiper-info">{{ user.info }}</div>
					</div>

				</swiper-slide>
			</swiper>
		</div>
		<div class="nb-hr-footer"/>
		<div class="nb-footer-section">
			<div class="nb-app-title-container">
				<img src="../images/app_logo.png" alt="app logo" class="nb-app-logo-image">
				<div class="nb-footer-app-title">星云加速器</div>
			</div>
			<div class="nb-footer-download-container">
				<div class="nb-footer-download-btn" @click="onWindowsDownloadClicked">
					<img src="../images/os_win_footer.svg" class="nb-footer-download-img" alt="windows">
					<div class="nb-footer-download-text">Windows版本下载</div>
				</div>
				<div class="nb-footer-download-btn" @click="onAndroidDownloadClicked">
					<img src="../images/os_and_footer.svg" class="nb-footer-download-img" alt="android">
					<div class="nb-footer-download-text">Android版本下载</div>
				</div>
				<div class="nb-footer-download-btn" @click="onIosDownloadClicked">
					<img src="../images/os_ios_footer.svg" class="nb-footer-download-img" alt="ios">
					<div class="nb-footer-download-text">iPhone版本下载</div>
				</div>
				<div class="nb-footer-download-btn" @click="onMacDownloadClicked">
					<img src="../images/os_ios_footer.svg" class="nb-footer-download-img" alt="imac">
					<div class="nb-footer-download-text">iMac版本下载</div>
				</div>
			</div>
			<div class="nb-footer-address-container">
				<div>
					Powered by Argocloud Inc.
				</div>
				<div>
					Company Address：543 Fillmore St, DENVER, CO, 80206, USA
				</div>
			</div>
			<div class="nb-footer-copy-right">
				版权所有 © 2024 星云加速器 团队
			</div>
		</div>
	</div>

</template>

<script>
import {
	getAndroidDownloadLink,
	getClientId,
	getUrlId,
	getUrlType,
	getWindowsDownloadLink,
	hideLoading,
	isSessionValid,
	showLoading,
	showToast,
	startDownload,
	getPlanInfo,
	reportVisit,
	reportGoogle,
	reportFb
} from "@/utils/methods";

import {userList} from "@/utils/constants";

import {Swiper, SwiperSlide} from 'swiper/vue';
import {A11y, Autoplay, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import ModalContainer from "@/components/ModalContainer";
import RegisterModalContent from "@/components/RegisterModalContent";
import UserInfoContent from "@/components/UserInfoContent";
import PaymentMethodsContent from "@/components/PaymentMethodsContent";

export default {
	name: "HomePage",
	components: {
		Swiper,
		SwiperSlide,
		ModalContainer,
		RegisterModalContent,
		UserInfoContent,
		PaymentMethodsContent
	},
	data() {
		return {
			currentComment: '',
			sliderPerView: 5,
			isMobileMenuOpen: false,
			isRegisterModalOpen: false,
			isAccountInfoModalOpen: false,
			isPaymentMethodModalOpen: false,
			confirmModalTitle: '',
			confirmCallback: null,
			showConfirmDialog: false,
			planInfoList: [],
			selectedPlanType: null,
			openWindowsDownloadModal: false
		};
	},
	setup() {
		return {
			userList,
			modules: [Navigation, Pagination, Autoplay, A11y],
		};
	},
	async mounted() {
		this.currentComment = this.userList[0].comment
		if (window.innerWidth < 900) {
			this.sliderPerView = 3
		} else {
			this.sliderPerView = 5
		}
		window.addEventListener('resize', () => {
			if (window.innerWidth < 900) {
				this.sliderPerView = 3
			} else {
				this.sliderPerView = 5
			}

			// 假设你的div元素有一个ID为'my-div'
			const nbBannerContainer = document.getElementById('nb_banner_container');

// 获取div的宽度
			const width = nbBannerContainer.clientWidth;

// 设置div的高度与宽度相同
			nbBannerContainer.style.height = width / 15 * 8 + 'px';
		});
		const result = await isSessionValid()
		if (result.err === false) {
			this.$store.dispatch('setLoginState', {isLogin: true, sessionExpireTimestamp: result.expiredAtTimestamp})
			this.$store.dispatch('setUserInfo', {email: result.email, planExpireTime: result.planExpireTime})
		}
		const planInfoResult = await getPlanInfo()
		if (planInfoResult.code === 200) {
			this.planInfoList = planInfoResult.msg?.planInfo
		}

		// 假设你的div元素有一个ID为'my-div'
		const nbBannerContainer = document.getElementById('nb_banner_container');

// 获取div的宽度
		const width = nbBannerContainer.clientWidth;

// 设置div的高度与宽度相同
		nbBannerContainer.style.height = width / 15 * 8 + 'px';

		await reportVisit()
	},
	computed: {
		isUserLogin() {
			return this.$store.state.isLogin
		}
	},
	methods: {
		onWindowsDownloadModalClose() {
			this.openWindowsDownloadModal = false
		},
		getPriceCardActionText(item) {
			if (this.isUserLogin === true) {
				return '立即购买'
			}
			if (item.promoCode) {
				return '登录领取'
			}

			return '登录购买'
		},
		async onAndroidDownloadClicked() {
			try {
				showLoading()
				const downloadLink = await getAndroidDownloadLink()
				showToast('开始下载...')
				startDownload(downloadLink)
				await reportVisit('android_download')
			} catch (e) {
				showToast('下载失败，请重试')
			} finally {
				hideLoading()
			}
		},
		async onWindowsDownloadClicked() {
			this.openWindowsDownloadModal = true
		},
		async downloadWindowsApp(type) {
			try {
				showLoading()
				const downloadLink = await getWindowsDownloadLink(type)
				showToast('开始下载...')
				startDownload(downloadLink)
				await reportVisit('windows_download')
			} catch (e) {
				showToast('下载失败，请重试')
			} finally {
				hideLoading()
			}
		},
		async onIosDownloadClicked() {
			this.confirmModalTitle = 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
			this.showConfirmDialog = true
			this.confirmCallback = async () => {
				this.showConfirmDialog = false
				window.open('https://www.alphacloud.cc', '_blank');
				await reportVisit('ios_download')
			}
		},
		async onMacDownloadClicked() {
			this.confirmModalTitle = 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
			this.showConfirmDialog = true
			this.confirmCallback = async () => {
				this.showConfirmDialog = false
				window.open('https://www.alphacloud.cc', '_blank');
				await reportVisit('macos_download')
			}
		},
		onSlideChange(swiper) {
			// console.log(swiper.activeIndex);

			this.currentComment = this.userList[(swiper.realIndex + 1) % this.userList.length].comment
		},
		onDownloadClicked() {
			const ele = document.getElementById("nb_download_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onProductFeatureClicked() {
			const ele = document.getElementById("nb_product_feature_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onPurchaseMembershipClicked() {
			const ele = document.getElementById("nb_purchase_membership_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onUserCommentClicked() {
			const ele = document.getElementById("nb_user_comment_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onMobileMenuClicked() {
			this.isMobileMenuOpen = true
		},
		onMobileMenuMaskClicked() {
			this.isMobileMenuOpen = false
		},
		onRegisterModalClose() {
			this.isRegisterModalOpen = false
		},
		onRegisterAccountClicked() {
			this.isMobileMenuOpen = false
			if (this.$store.state.isLogin === true) {
				this.isAccountInfoModalOpen = true
			} else {
				this.isRegisterModalOpen = true
			}
		},
		onRegisterAccountSuccess() {
			this.isRegisterModalOpen = false
		},
		onLoginSuccess() {
			this.isRegisterModalOpen = false
		},
		onAccountInfoClose() {
			this.isAccountInfoModalOpen = false
		},
		onUserLogout() {
			this.confirmModalTitle = '确认登出？'
			this.showConfirmDialog = true
			this.confirmCallback = () => {
				this.showConfirmDialog = false
				this.isAccountInfoModalOpen = false
				this.$store.dispatch('logoutUser')
			}
		},
		onPaymentMethodModalClose() {
			this.isPaymentMethodModalOpen = false
		},
		onPaymentMethodModalOpen(planType) {
			if (this.$store.state.isLogin !== true) {
				showToast('请登录后购买')
				return
			}
			this.isPaymentMethodModalOpen = true
			this.selectedPlanType = planType
		},
		onConfirmBtnClick() {
			if (this.confirmCallback) {
				this.confirmCallback()
			}
		},
		onCancelBtnClick() {
			this.showConfirmDialog = false
		},
	}
}
</script>

<style scoped>
.nb-home-page-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	position: relative;
}

.nb-mobile-menu-container {
	display: none;
}

.nb-first-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1em;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
}

.nb-banner-image-container {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nb-banner-image {
	top: 0;
	left: 0;
	height: 100%;
	z-index: 1;
	opacity: 0.4;
}

.nb-header-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	color: #ff8900;
	justify-content: center;
	align-items: center;
	z-index: 2;
	margin-bottom: 3em;
}

.nb-web-logo {
	width: 2.2em;
	height: 2.2em;
	margin-right: 0.5em;
}

.nb-web-title {
	flex: 1;
	font-size: 1.8em;
	line-height: 1.8em;
	text-align: start;
}

.nb-menu-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 1em;
}

.nb-menu-item {
	font-size: 1.6em;
	margin: 0 1em;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.nb-sub-header {
	color: #ff8900;
	font-size: 1.6em;
	text-align: center;
	margin-bottom: 2em;
	z-index: 3;
	text-shadow: 1px 1px 5px #000000;
}

.nb-first-download-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;
}

.nb-products-image {
	width: 40em;
}

.nb-product-image-section {
	padding: 5em 2em;
}

.nb-download-text-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	align-self: stretch; /* 让子元素填满flex容器的交叉轴 */
}

/*.nb-download-text-section::after {*/
/*	content: '';*/
/*	position: absolute;*/
/*	top: 0;*/
/*	left: 0;*/
/*	right: 0;*/
/*	bottom: 0;*/
/*	background: rgba(0, 0, 0, 0.4); !* 在此设置覆盖层的颜色和透明度 *!*/
/*	z-index: 1; !* 确保覆盖层出现在图片之上 *!*/
/*}*/

.nb-first-section-download-btn {
	color: #ff8900;
	font-size: 1.6em;
	line-height: 1.6em;
	background-color: rgb(140, 81, 15);
	padding: 0.2em 1em;
	border-radius: 0.2em;
	margin-top: 0.4em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 3;
}

.nb-download-file-image {
	width: 1.2em;
	margin-left: 0.4em;
}

.nb-hr {
	background: radial-gradient(49.07% 49.07% at 50% 50.93%, #ff8900eb 0, rgba(49, 0, 0, 0) 100%);
	width: 100%;
	height: 1.5px;
	min-height: 1.5px;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	margin: 2em 0;
}

.nb-hr-footer {
	background: radial-gradient(49.07% 49.07% at 50% 50.93%, #ff8900eb 0, rgba(49, 0, 0, 0) 100%);
	width: 100%;
	height: 1.5px;
	min-height: 1.5px;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	margin-top: 2em;
}

.nb-feature-section {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.nb-feature-title {
	font-size: 2em;
	color: #E6D5B8;
}

.nb-download-section-title {
	font-size: 2em;
	color: #e45826;
}

.nb-feedback-section {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.nb-footer-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #000000;
	padding: 3em 0;
	align-items: center;
	justify-content: center;
}

.nb-price-section-title {
	font-size: 2em;
	color: #ff8900;
}

.nb-price-section {
	width: 100%;
	display: flex;
	flex-direction: column;
}


.nb-feature-grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1em;
	padding: 3em 9em;
}

.nb-feature-img {
	width: 3em;
}

.nb-feature-card-text {
	font-size: 1.2em;
	color: #E6D5B8;
	margin-top: 1em;
}

.nb-feature-card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(230, 213, 184, 0.3);
	padding: 1em 0;
	border-radius: 0.2em;
}

.nb-os-download-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 6em;
	padding: 3em;
}

.nb-os-download-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1em 0;
}

.nb-os-download-image {
	height: 8em;
	max-width: 8em;
}

.nb-os-download-text {
	font-size: 1.6em;
	color: #e45826;
	padding: 1em 0;

}

.nb-os-download-btn {
	background-color: #e45826;
	font-size: 1.4em;
	line-height: 1.4em;
	color: #E6D5B8;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	cursor: pointer;
}

.nb-price-card-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 6em;
	padding: 3em;
}

.nb-price-card {
	background-color: rgba(255, 137, 0, 0.3);
	border-radius: 0.4em;
	color: #ff8900;
	padding: 2em 0;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	position: relative;
}

.nb-price-card-first-charge-tag {
	position: absolute;
	width: 4em;
	top: 0;
	right: 0;
}

.nb-price-card-title {
	font-size: 1.4em;
	color: #ff8900;
	margin-bottom: 0.8em;
	font-weight: bold;
}

.nb-price-card-promo-title {
	font-size: 1.4em;
	color: #e45826;
	margin-bottom: 0.8em;
	font-weight: bold;
}

.nb-price-card-price, .nb-price-card-text {
	font-size: 1.4em;
	color: #ff8900;
	margin-bottom: 0.8em;
}

.nb-price-card-text-with-strip {
	font-size: 1.4em;
	color: #ff8900;
	margin-bottom: 0.8em;
	text-decoration-line: line-through; /* 定义装饰线类型为上划线 */
	text-decoration-color: #ff8900; /* 定义装饰线颜色为黑色 */
	text-decoration-style: solid; /* 定义装饰线样式为实线 */
}

.nb-price-card-promo-text {
	font-size: 1.8em;
	color: #ffe8e0;
	margin-bottom: 0.8em;
	text-shadow: 0 0 6px #e45826; /* 添加阴影 */
	animation: double-blink 3.6s ease-in infinite;

}

.nb-price-card-btn {
	background-color: #ff8900;
	font-size: 1.4em;
	line-height: 1.4em;
	color: #1f1f35;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	cursor: pointer;
	width: fit-content;
	margin-top: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nb-app-logo-image {
	width: 1.4em;
	margin-right: 0.8em;
}

.nb-app-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.nb-footer-app-title {
	color: #ff8900;
	font-size: 1.2em;
	line-height: 1.2em;
	font-weight: bold;
}

.nb-footer-download-container {
	display: flex;
	flex-direction: row;
	padding: 1.6em 0;
	width: fit-content;
}

.nb-footer-download-img {
	width: 1em;
	max-height: 1em;
	margin-right: 0.4em;
}

.nb-footer-download-btn {
	display: flex;
	flex-direction: row;
	color: #ff8900;
	margin-right: 2em;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.nb-footer-download-text {

}

.nb-footer-address-container {
	color: #ff8900;
	font-size: 1em;
	margin-top: 1em;
}

.nb-footer-copy-right {
	color: #ff8900;
	font-size: 1em;
	margin-top: 1em;
}


.nb-swiper-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #E6D5B8;
}

.nb-swiper-container {
	width: 100%;
	margin-bottom: 3em;
	pointer-events: none;
}

.nb-swiper-container .nb-swiper-slide-container {
	pointer-events: all;
}

.nb-swiper-name {
	font-size: 1em;
	margin-top: 0.4em;
}

.nb-swiper-info {
	font-size: 1.1em;
	margin-top: 0.2em;
}

.nb-feedback-comment {
	padding: 3em 12em;
	color: #E6D5B8;
	font-size: 1.4em;
}

.nb-download-text-section::after {
	content: "";
	background-image: url('../images/banner.png');
	background-size: 100%; /* 宽度是100%，高度自动 */
	background-repeat: no-repeat;
	opacity: 0.5; /* 设置透明度为0.5 */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
}

@media (max-width: 900px) {
	.nb-products-image {
		width: 80% !important;
	}

	.nb-menu-item {
		display: none;
	}

	.nb-first-download-container {
		flex-direction: column !important;
	}

	.nb-download-text-section {
		order: 2;
	}

	.nb-banner-image-container {
		width: fit-content;
		height: fit-content;
	}

	.nb-banner-image {
		width: 100% !important;
		height: fit-content !important;
	}

	.nb-feature-grid-container {
		padding: 1em !important;
	}

	.nb-feature-card-text {
		min-height: 2em;
		font-size: 1.1em;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.nb-os-download-container {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4em;
	}

	.nb-price-card-container {
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 1em !important;
	}

	.nb-feature-grid-container {
		grid-template-columns: repeat(2, 1fr);
	}

	.nb-feedback-comment {
		padding: 2em;
		color: #E6D5B8;
		font-size: 1.2em;
	}

	.nb-product-image-section {
		z-index: 10 !important;
	}

	.nb-swiper-img {
		width: 100% !important;
		height: fit-content !important;
	}

	.nb-footer-download-container {
		padding: 1em 0;
		width: 100% !important;
	}

	.nb-footer-download-btn {
		margin: 0 !important;
		flex: 1;
		font-size: 0.8em;
	}

	.nb-footer-address-container {
		margin-top: 0;
	}

	.nb-footer-section {
		padding: 2em 0;
	}

	.nb-menu-img {
		display: block !important;
	}

	.nb-mobile-menu-container {
		display: flex;
		position: fixed;
		flex-direction: column;
		right: 0;
		top: 0;
		height: 100%;
		color: #ff8900;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.8);
		font-size: 2em;
		padding: 1em 0.4em;
		align-items: start;
		justify-content: start;
		min-width: 7em;
	}

	.nb-mobile-menu-item {
		margin: 0.4em 0;
		border-bottom: 1px solid #ff8900;
		width: 100%;
		text-align: start;
		padding: 0.2em;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: start;
	}

	.nb-mobile-menu-mask {
		display: block !important;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 99;
	}

	.nb-header-container {
		background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7959558823529411) 50%, rgba(0, 212, 255, 0) 100%);
		position: fixed;
		top: 0;
		left: 0;
		padding: 0.6em;
		box-sizing: border-box;
		z-index: 20 !important;
		margin: 0 !important;
	}


	.select-download-type-container {
		padding: 0 !important;
	}

	.select-download-type-column {
		margin: 0 0.4em !important;
		padding: 0.4em !important;
		font-size: 0.9em !important;
	}

	.select-download-framework {
		font-size: 0.9em !important;
	}

	.select-download-type-download-btn {
		padding: 0.2em 0.6em !important;
		font-size: 0.9em !important;
	}
}

.nb-mobile-menu-mask {
	display: none;
}

.nb-menu-img {
	display: none;
}

.blur {
	filter: blur(10px);
}

.nb-mobile-menu-image {
	width: 1em;
	margin-right: 0.4em;
}

.nb-mobile-close-menu-image {
	width: 0.9em;
}

@keyframes blink {
	0%, 100% {
		opacity: 1; /* 不透明（完全可见） */
	}
	50% {
		opacity: 0; /* 完全透明（不可见） */
	}
}

@keyframes double-blink {
	0%, 10% {
		opacity: 1;
	}
	5%, 15% {
		opacity: 0;
	}
	20%, 100% {
		opacity: 1;
	}
}

.select-download-type-container {
	display: flex;
	flex-direction: row;
	padding: 1em;
	width: 100%;
	box-sizing: border-box;
}

.select-download-type-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.8em;
	box-sizing: border-box;
	flex: 1;
	margin: 0 1em;
	border-radius: 0.2em;
	border: 1px solid #E6D5B8;
}

.select-download-framework {
	color: #E6D5B8;
	font-size: 1em;
}

.select-download-system {
	color: #E6D5B8;
	margin-top: 1em;
	font-size: 1em;
	flex: 1;
	width: 100%;
	text-align: start;
}

.select-download-type-download-btn {
	font-size: 1em;
	padding: 0.2em 0.8em;
	color: #2c3e50;
	background-color: #FF8900;
	border-radius: 0.2em;
	margin-top: 1em;
	width: fit-content;
	cursor: pointer;
}

</style>